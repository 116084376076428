$or-primary: #000000;
$or-secondary: #FF0000;
$or-red: #FF0000;
$or-green: #00FF00;
$or-blue: #0000FF;
$or-yellow: #cbfc25;
$or-bg-shade1: #031251;
$or-bg-shade2: #03134a;
$or-text-primary: #ffffff;
$or-text-secondary: #ccc;
$ff-Varino: 'OCR Std A';
$ff-Aleo: 'Aleo-Regular';
.like-color {
    color: $or-green !important;
}

.dislike-color {
    color: $or-red !important;
}

body {
    background-color: $or-primary !important;
    overflow-x: hidden !important;
    background-image: url("../img/Landing_Page/blurred-main-banner-40.jpg");
    background-size: contain;
}

.text-primary {
    color: $or-secondary !important;
}

.text-secondary {
    color: $or-text-secondary !important;
}

.bg-primary {
    background-color: $or-primary !important;
    border-radius: 10px !important;
    border: 1px solid $or-text-primary;
}

.bg-primary1 {
    background-color: $or-primary !important;
    border-radius: 10px !important;
    padding: 20px;
}

.bg-primary2 {
    background-color: #05165c !important;
    border-radius: 10px !important;
}

.discover-join-community {
    font-size: 12px !important;
}

.border-rad {
    border-radius: 10px !important;
}

.bg-shade2 {
    .modal-content {
        background-color: $or-bg-shade2 !important;
    }
}

.index-page {
    background-image: none !important;
}

section {
    padding: 90px 60px;
}

.bg-banner {
    width: 600px;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $ff-Varino !important;
    color: white !important;
    line-height: 1.4;
    text-transform: uppercase;
}

p {
    font-family: $ff-Aleo !important;
    font-size: 14px !important;
}

span {
    font-family: $ff-Aleo !important;
    font-size: 16px !important;
}

.public-DraftEditorPlaceholder-inner {
    font-size: 16px !important;
}

.middle-info-bg {
    p {
        font-family: $ff-Aleo !important;
        font-size: 14px;
    }
}

.btn-bg {
    background-color: $or-secondary !important;
    height: 80px !important;
    span {
        font-size: 16px;
        font-weight: 600px;
        color: white;
    }
}

.bg-right {
    background-image: URL('../../assets/img/Landing_Page/slider-bg.png') !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    p {
        font-size: 20px;
        font-weight: 400px !important;
    }
}

.birthday-input {
    background-color: transparent !important;
    border-color: $or-text-primary !important;
    color: $or-text-primary !important;
    border-radius: 0;
}

.wallet-bg {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid $or-text-primary;
    min-height: 190px;
    min-width: 200px;
}

.wallet-bg-first {
    background-color: transparent !important;
    border-radius: 10px;
    border: 1px solid #d89a43 !important;
    min-height: 190px;
    min-width: 200px;
}

.check-text {
    span {
        color: $or-text-secondary;
        font-size: 16px !important;
    }
}

.modal-slide-bg {
    background-color: #05165c;
    border: 1px solid #d89a43;
    border-radius: 10px;
}

.search-box1 {
    background-color: transparent !important;
    border-radius: 0px !important;
    border-color: $or-text-primary !important;
}

.search-box {
    background-color: #05165c !important;
    border-radius: 10px !important;
    border-color: black !important;
}

.middle-info-bg {
    background-color: transparent !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: 2px solid $or-text-primary;
    height: min-content;
}

.social-info-bg {
    background-color: transparent !important;
    border-radius: 10px !important;
    border: 2px solid $or-yellow;
    .level {
        color: #ee9a25 !important;
    }
}

.rec .rec-arrow-left {
    display: none !important;
}

.rec .rec-arrow-right {
    display: none !important;
}

.krmNah {
    background-color: $or-secondary !important;
    box-shadow: 0 0 1px 3px rgb(206, 177, 16) !important;
}

.jhmYzC {
    background-color: white !important;
    box-shadow: 0 0 1px 2px rgba(245, 241, 241, 0.5);
}

.modal-btn {
    background-image: none !important;
    color: $or-text-primary;
    background-color: $or-blue !important;
    border: 2px solid $or-text-primary;
    transition: background-color .5s ease-in;
}

.modal-btn:hover {
    background-image: none !important;
    background-color: $or-red !important;
    border: 2px solid transparent;
}

.modal-btn:active {
    background-image: none !important;
    background-color: $or-secondary !important;
}

.modal-btn:focus {
    background-image: none !important;
    background-color: $or-secondary !important;
}

.menu-active {
    h4,
    svg {
        color: $or-green !important;
    }
    //background-image: none !important;
    //background-color: $or-secondary !important;
}

.rank-level {
    font-family: $ff-Varino;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 24px;
    text-align: center;
    background: #fea321;
    color: #fff;
}

.progress {
    background-color: $or-primary !important;
    height: 2.5rem !important;
    border: 1px solid $or-red;
    .progress-value {
        font-size: 20px;
        font-weight: 700;
        color: #6b7880;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.progress-bar {
    background-color: $or-red !important;
    border-radius: 15px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.progress-bar-edit {
    background-color: $or-red !important;
    border-radius: 15px !important;
    padding-left: 2px;
    text-align: left !important;
}

.progress-outer {
    background: #fff;
    border-radius: 45px;
    margin-bottom: 20px;
    position: relative;
}

.position-rel {
    position: relative;
}

.position-abs {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50px;
}

.position-abs1 {
    position: absolute;
    right: 10px;
    top: 150px;
}

.position-abs2 {
    position: absolute;
    top: 12px;
}

.position-abs3 {
    position: absolute;
    bottom: 80px;
    left: 0;
}

.position-abs4 {
    position: relative;
    margin-top: -50px !important;
    left: 0;
    right: 0;
    margin: auto;
    top: 0px;
}

.position-abs6 {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
}

.position-parent {
    position: relative;
}

.position-abs5 {
    position: absolute;
    margin: auto;
    width: 30%;
}

.position-abs7 {
    position: absolute;
    margin: auto;
    width: 30%;
}

.nft-logo {
    margin-top: -2rem;
    margin-left: 1rem;
}

.nft-logo1 {
    margin-top: -3rem;
    margin-left: 13.5rem;
}

.image-width {
    width: -moz-available;
}

.image-width1 {
    width: 560px !important;
}

.number {
    font-family: "Varino-Regular";
    width: 33px;
    height: 33px;
    border-radius: 50%;
    font-size: 10px !important;
    text-align: center;
    background: #fea321;
    color: #fff;
    padding: 8px;
    display: inline-block;
}

.number2 {
    font-family: "Varino-Regular";
    width: 22px;
    height: 22px;
    min-width: 22px;
    border-radius: 50%;
    font-size: 8px !important;
    text-align: center;
    background: $or-green;
    color: $or-primary;
    padding: 5px;
    display: inline-block;
}

.feed-border-right {
    border-width: 1px;
    border-style: solid;
    border-left: 0;
    border-image: linear-gradient(rgb(158, 160, 180), rgb(157, 159, 175), rgb(36, 31, 78)) 0 100%;
}

.community-details-icon {
    h5 {
        font-size: 12px !important;
    }
}

.text-member {
    color: #979797 !important;
}

.rc-slider-rail {
    background-color: #2757b0 !important;
    height: 0.8rem !important;
}

.rc-slider-track {
    background-color: #fea321 !important;
    height: 0.8rem !important;
}

.rc-slider-handle {
    background-color: #fea321 !important;
    height: 1.2rem !important;
    width: 1.2rem !important;
    border: solid 4px #ffffff !important;
    border-radius: 50%;
    margin-top: -0.2rem;
}

input::placeholder {
    color: rgb(231, 216, 216) !important;
}

.navbar-collapse {
    z-index: 3 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    position: fixed !important;
}

.banner-section {
    background-image: URL('../../assets/img/Landing_Page/Banner_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    h2,
    img {
        position: absolute;
        bottom: 5.5rem;
        line-height: 1.5;
        padding-right: 2rem;
        margin-right: 1rem;
    }
}

.outreach-section {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-content {
    background-color: $or-primary !important;
    border: 1px solid $or-yellow;
    .comment-bg {
        border: 1px solid $or-text-primary;
    }
}

.listitemstyle {
    background-color: $or-primary !important;
    border: 0.0625rem solid $or-text-secondary !important;
}

.resultBox {
    position: absolute;
    z-index: 2 !important;
    left: 0;
    right: 0;
    margin-right: 50px;
    margin-left: 50px;
}

.parentlist {
    border-radius: 20px !important;
}

.optionbox {
    position: relative;
    z-index: 1;
}

.optionpost {
    background-color: $or-secondary !important;
}

.optionpostitem {
    color: $or-text-primary !important;
}

.textareacontainer {
    border-left: 1px solid #2b3553 !important;
    border-top: 1px solid #2b3553 !important;
    border-right: 1px solid #2b3553 !important;
}

.fileinput {
    opacity: 1 !important;
    position: initial !important;
}

.actiongrid {
    background-color: transparent !important;
    border: 1px solid $or-text-primary;
    border-radius: 20px !important;
}

.actiontypes {
    color: $or-green !important;
}

.modal-line {
    border-top: 1px solid $or-text-secondary !important;
    margin-bottom: 0rem !important;
}

.scrolling-enable {
    max-height: 458px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrolling-enable1 {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrolling-enable2 {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrolling-enable3 {
    max-height: 1000px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrolling-enable4 {
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.create-community {
    background-color: transparent;
    border-radius: 30px;
    border: 2px solid $or-text-primary;
    padding: 10px;
    transition: background-color .5s ease-in;
    &:hover {
        background-color: $or-red;
        border-radius: 50%;
    }
}

.icon-color {
    color: $or-text-primary !important;
}

.comment-bg {
    background-color: transparent !important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    color: white;
    word-break: break-word;
}

.com-like-button {
    border-radius: 20px;
    color: $or-green;
    padding: 5px;
}

.com-like-button1 {
    border-radius: 20px;
    padding: 5px;
}

.fileinput {
    width: 350px;
    max-width: 100%;
    color: white;
    background: transparent;
    border-radius: 10px;
    border: 1px solid #555;
}

.fileinput::file-selector-button {
    margin-right: 20px;
    border: none;
    background: $or-secondary;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
}

.avatar-img {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.avatar-img-original {
    background-color: $or-primary;
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.avatar-img6 {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-imginside {
    border-radius: 50px !important;
    border-radius: 50% !important;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: 0;
    right: 0;
    // width: calc(100% - 5px);
    // height: calc(100% - 5px);
    transform: translate(-50%, -50%);
    // object-fit: cover;
    // object-position: center;
}

.avatar-imginside1 {
    border-radius: 50px !important;
}

.avatarinput {
    opacity: 0;
    position: relative;
    bottom: 100px;
    width: 100%;
    height: 100%;
}

.avatarinput:hover {
    opacity: 0;
    position: relative;
    bottom: 100px;
    width: 100%;
    height: 100%;
}

.avatarinput2 {
    opacity: 0 !important;
    position: relative;
    width: 50% !important;
    height: 100%;
    bottom: 20px;
}

.avatarinput3 {
    opacity: 1 !important;
    width: 50% !important;
    height: 100%;
}

.avatarinput1 {
    opacity: 1;
    position: relative;
    width: 100%;
    height: 100%;
}

.avatar-img1 {
    background-color: white;
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50px;
    margin: 0 auto;
    text-align: center;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    .mt-1 {
        margin-top: 0.15rem !important;
    }
}

.avatar-img1-original {
    background-color: $or-primary;
    border: 2px solid $or-text-primary;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    min-width: 50px;
    border-radius: 50px;
    text-align: center;
    position: relative;
    .mt-1 {
        margin-top: 0.15rem !important;
    }
}

.avatar-img2 {
    background-color: white;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    position: relative;
    text-align: center;
}

.avatar-img3 {
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    margin-top: 5px;
    position: relative;
}

.avatar-img3-original {
    background-color: transparent;
    border: 1px solid $or-text-primary;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50px;
    text-align: center;
    position: relative;
}

.avatar-img4 {
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    text-align: center;
    position: relative;
}

.avatar-img5 {
    background-color: white;
    position: relative;
}

.profile-edit-cont:hover {
    .profile-edit-icon {
        display: block;
    }
}

.profile-edit-icon {
    color: white;
    display: block;
}

.notification-edit-icon {
    color: white;
}

.popover {
    background-color: $or-primary !important;
    border: 1px solid $or-text-primary;
}

.popover-header {
    background-color: $or-primary !important;
}

.popover-notification {
    border: 2px solid #2757b0 !important;
}

.bg-secondary {
    background-color: #05165c !important;
}

.spinner-index {
    z-index: 1;
}

.bg-third:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    backdrop-filter: blur(20px);
    border-radius: 10px;
    // padding-left: 4px;
    // padding-right: 4px;
}

.bg-fourth {
    background-color: #05165c !important;
    padding: 15px;
    border-radius: 10px;
}

.modal-content .modal-body img {
    padding: 2px !important;
    object-fit: cover;
    height: auto;
    display: block;
}

.modal-content .modal-header button {
    padding: 0.5rem !important;
}

.close {
    color: #fd5d93 !important;
    opacity: 1 !important;
    cursor: pointer !important;
    span {
        font-size: 30px !important;
        cursor: pointer !important;
    }
}

.model-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 20px;
}

.model-border {
    border: 1px solid #1d8cf8;
}

.reactEasyCrop_Contain {
    position: relative !important;
}

.reactEasyCrop_Container {
    position: relative !important;
}

.slider {
    padding: 22px 0px;
}

.crop-container {
    min-height: 300px;
}

.modal.show .modal-dialog {
    transform: none !important;
}

.noti-name-color {
    color: white;
}

.noti-action-color {
    color: #00f2c3;
}

.stickey {
    position: sticky !important;
    top: 0 !important;
}

.profile-image-edit-icon {
    display: flex !important;
    cursor: pointer;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    z-index: 1;
}

.profile-image-edit-icon:hover {
    display: flex !important;
    opacity: 1.5;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    color: white;
    z-index: 1;
}

.social-links-add {
    text-decoration: none;
    color: $or-green !important;
    font-weight: 500;
    font-family: $ff-Varino;
}

.fraction {
    color: $or-green !important;
}

.badge-img {
    filter: invert(100%) sepia(100%) hue-rotate(90deg) saturate(500%);
}

.social-link-display {
    text-decoration: underline;
    font-size: 10px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn1 {
    border: 2px solid $or-secondary;
    color: white;
    background-color: $or-secondary;
    padding: 4px 10px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: bold;
}

.heading1 h4 {
    font-size: 20px;
    color: $or-green;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.my-rich-text-editor {
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 10px;
    background-color: #05165c !important;
    border-color: black !important;
}

.my-rich-text-editor .rte-editor {
    min-height: 200px;
}

.mentionStyle {
    border-radius: 15px;
}

.mentionStyle__input {
    color: white !important;
}

.mentionStyle__highlighter {
    height: 36px !important;
}

.mentionStyle__control {
    margin-left: -15px !important;
}

.editor {
    box-sizing: border-box;
    cursor: text;
    padding: 10px;
    background-color: transparent !important;
    border-radius: 10px !important;
    border: 1px solid $or-text-primary !important;
    font-size: 16px !important;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

.public-DraftEditorPlaceholder-root {
    color: #FFF !important;
    font-size: smaller !important;
}

// Custom Css Start
.main-login-screen {
    overflow-x: hidden;
    .bg-right {
        .login-text {
            text-transform: uppercase;
        }
    }
}

.main-login-screen .checkbox-main .check-text {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.main-login-screen .checkbox-main input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.main-login-screen .checkbox-main label.check-text {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
}

.main-login-screen .checkbox-main label.check-text:before {
    position: absolute;
    top: 0.25rem;
    left: -1px;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.main-login-screen .checkbox-main input[type=checkbox]:checked~label.check-text:before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
    background-image: URL('../../assets/img/tick.svg') !important;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.main-login-screen .wallet-bg,
.main-login-screen .wallet-bg-first {
    min-width: 180px;
}

.modal .rec.rec-pagination .rec-dot {
    border: 1px solid #fff;
    box-shadow: none;
}

.modal .rec.rec-pagination .rec-dot.rec-dot_active {
    background-color: #fff;
}

.main-login-screen .wallet-bg img,
.wallet-bg-first img {
    width: 40px;
    margin-bottom: 10px;
}

// .main-login-screen .outrider-logo{}
.image-width {
    width: 100%;
}

.avtar-img-search img {
    width: 100%;
}

.bg-right p {
    word-break: break-word;
}

.bootstrap-select .dropdown-menu.inner li a,
.dropdown-menu .dropdown-item {
    margin-top: 0px;
}

.main-modal-comment form {
    width: 100%;
}

.dropdown-menu:before {
    color: #fea321;
}

.video-section .image-width.rounded {
    height: auto !important;
}

.video-section .image-width.rounded div {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    height: auto !important;
}

.video-section .image-width.rounded div iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.bg-primary1 h6 {
    line-height: 1.6;
}

.toggle-icon img {
    min-width: 22px;
}

.progress .progress-bar h5 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

.img-joined img {
    width: 100%;
}

input,
.form-control {
    font-size: 16px !important;
}

body.navbar-open {
    overflow-y: hidden;
}

.cybot-chat-button {
    background: $or-primary;
    position: fixed;
    bottom: 10px;
    right: 30px;
    border: 2px solid $or-green;
    border-radius: 40px;
    height: 50px;
    width: 50px;
    outline: none !important;
    cursor: pointer;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .cybot-chat-button {
        padding: 0;
    }
}

.cybot-pop-up {
    z-index: 1;
    position: fixed;
    bottom: 70px;
    right: 30px;
    height: 400px;
    width: 350px;
    .cybot-inner-content {
        height: 400px;
        width: 350px;
        background: $or-primary;
        border: 1px solid $or-green;
        .cs-typing-indicator__dot {
            background-color: $or-green;
            color: $or-green;
        }
        .cs-message__content {
            background-color: transparent !important;
            color: $or-green !important;
        }
        .cs-typing-indicator {
            background-color: #000 !important;
            color: $or-green;
            font-size: 7px;
            font-family: "Varino-Regular" !important;
            .cs-typing-indicator__text {
                color: $or-green;
            }
        }
        .section.cs-message {
            padding: 0 !important;
        }
        .cs-message-input__content-editor-wrapper,
        .cs-message-input__content-editor,
        .cs-message--outgoing .cs-message__content {
            background-color: transparent !important;
            color: $or-text-primary !important;
            font-family: "Varino-Regular" !important;
        }
        .cs-main-container,
        .cs-chat-container,
        .cs-message-list,
        .scrollbar-container {
            background-color: transparent;
            border: none !important;
        }
        .scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y {
            padding: 5px;
        }
        .cs-message-list__scroll-wrapper>.cs-message {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0 !important;
        }
        .cs-message__content {
            color: #fff;
            font-size: 16px;
            font-family: "Varino-Regular" !important;
        }
        .cs-button--attachment {
            display: none;
        }
        .cs-message-input {
            background-color: transparent !important;
            border-top: none;
            padding: 5px;
        }
        .cs-message-input__tools path {
            fill: #FF0000 !important;
        }
        .cs-message-input__content-editor[data-placeholder]:empty:before {
            color: #fff;
        }
    }
    &.hide {
        display: none;
    }
}

.main-input-image .avatarinput2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 50%;
    margin: 0 auto;
    height: 100%;
    cursor: pointer;
}

.nav-item {
    h4 {
        line-height: 2;
    }
}

@media(max-width:1366px) {
    .main-input-image .modal-btn {
        padding: 11px 20px;
    }
}

@media(max-width:1199px) {
    .profile-image-edit-icon:hover {
        top: 0px;
        background-color: $or-secondary;
    }
}

@media(max-width:1200px) {
    .position-abs4 {
        position: relative !important;
        top: 0;
        margin-top: 0 !important;
    }
    .main-login-screen .wallet-bg {
        min-width: 160px !important;
    }
    .number2 {
        width: 18px;
        height: 18px;
        min-height: 18px;
        min-width: 18px;
        line-height: 1;
    }
    .bootstrap-select .dropdown-menu.inner li a,
    .dropdown-menu .dropdown-item {
        padding: 4px 15px;
    }
}

@media(max-width:991px) {
    .outrider-logo {
        width: 200px;
    }
}

@media(max-width:575px) {
    .modal-header {}
    .image-video-input .form-control-file {
        position: absolute;
    }
    .upload-input {
        width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        height: 30px;
    }
    // .avatar-img3-original{
    //     margin-top: 15px;
    // }
    h4 {
        font-size: 16px !important;
    }
    h3 {
        font-size: 18px !important;
    }
    .avatar-img1-original,
    .avatar-img1 {
        margin-bottom: 10px;
    }
    .comment-bg {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 10px;
    }
    .avatar-img6 {
        width: 80px;
        height: 80px;
    }
    .modal-content .modal-footer button {
        padding: 10px;
    }
    .modal-content .avatar-img3-original {
        margin: 0;
        margin-top: 5px;
        margin-left: -15px;
        min-width: 35px;
        min-height: 35px;
        height: 35px;
        width: 35px;
    }
}

@media(max-width:375px) {
    h3 {
        font-size: 15px !important;
    }
    .dropdown-user.nav-item.dropdown img {
        min-width: 7px;
    }
    .modal-header {
        padding: 24px 10px 0 10px;
    }
}

@media(min-width:1200px) {
    body .avatar-img-original {
        position: relative !important;
        z-index: 1;
        top: 0;
        margin-top: -50px;
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

// .main-login-screen .check-text{}
@media(max-width:1600px) {
    .heading1 h4 {
        font-size: 16px;
    }
    .create-community {
        padding: 5px;
        margin-left: 15px;
    }
}

@media(max-width:767px) {
    .listing-data .dropdown-menu {
        top: auto !important;
        bottom: calc(100% + 15px) !important;
    }
    .listing-data .dropdown-menu.dropdown-menu-right:before,
    .listing-data .dropdown-menu.dropdown-menu-right:after {
        top: 100%;
        bottom: auto;
        transform: rotate(180deg);
    }
}

.popover .popover-inner .popover-header::before {
    display: none;
}

@media(max-width:575px) {
    .avatar-img3 {
        width: 30px;
        height: 30px;
    }
    .modal-content .modal-body .avatar-imginside {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
    }
    // .modal-content .modal-body .main-image-preview{width: auto;height: auto;min-width: auto;min-height: auto;}
}

@media(max-width:991px) {
    .cybot-chat-button {
        background: transparent;
        position: fixed;
        bottom: 10px;
        right: 30px;
        border: 2px solid white;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        cursor: pointer;
        background: #090e46;
        svg {
            height: 20px;
            width: 20px;
        }
    }
    .cybot-pop-up {
        position: fixed;
        bottom: 50px;
        right: 30px;
        height: 300px;
        width: 250px;
        .cybot-inner-content {
            height: 300px;
            width: 250px;
            background: #020f47;
            .cs-message__content {
                background-color: #fea321 !important;
                color: #fff;
                font-size: 16px;
            }
            .cs-typing-indicator {
                background-color: #fea321 !important;
                color: #fff;
                font-size: 7px;
                font-family: "Varino-Regular" !important;
                .cs-typing-indicator__text {
                    color: #fff;
                    font-size: 7px;
                }
            }
            .cs-message-input__content-editor-wrapper,
            .cs-message-input__content-editor,
            .cs-message--outgoing .cs-message__content {
                background-color: #914d36 !important;
                color: #fff;
                font-size: 16px;
                font-family: "Varino-Regular" !important;
            }
            .cs-message-input__content-editor {
                font-size: 16px;
            }
            .section.cs-message {
                padding: 0 !important;
            }
            .cs-main-container,
            .cs-chat-container,
            .cs-message-list,
            .scrollbar-container {
                background-color: transparent;
            }
            .scrollbar-container.cs-message-list__scroll-wrapper.ps.ps--active-y {
                padding: 5px;
            }
            .cs-message-list__scroll-wrapper>.cs-message {
                margin-top: 7px;
                margin-bottom: 7px;
            }
            .cs-message__content {
                color: #fff;
                font-size: 16px;
                font-family: "Varino-Regular" !important;
            }
            .cs-button--attachment {
                display: none;
            }
            .cs-message-input {
                background-color: transparent !important;
                border-top: none;
                padding: 5px;
            }
            .cs-message-input__tools path {
                fill: #FF0000 !important;
            }
            .cs-message-input__content-editor[data-placeholder]:empty:before {
                font-size: 15px;
            }
            .section.cs-message {
                padding: 0 !important;
            }
            .cs-message-input__content-editor-wrapper {
                padding: 5px;
            }
        }
        &.hide {
            display: none;
        }
    }
}

@media(max-width:575px) {
    .cybot-pop-up {
        height: 85%;
        width: 80%;
        .cybot-inner-content {
            height: 100%;
            width: 100%;
        }
    }
}