@media screen and (max-width: 1200px) {
    .resultBox {
        margin-right: 20px;
        margin-left: 20px;
    }

    section {
        padding: 40px 20px !important;
    }

    .login-text {
        font-size: 28px !important;

    }


    .position-abs1 {
        position: static !important;
    }

    .feed-border-right {
        border-right: none !important;
    }


    .wallet-bg {
        background-color: $or-bg-shade1;
        border-radius: 10px;
        min-height: 190px;
        min-width: 180px !important;
    }

    .wallet-bg-first {
        background-color: transparent !important;
        border-radius: 10px;
        border: 1px solid #d89a43 !important;
        min-height: 190px;
        min-width: 180px !important;
    }
}

@media screen and (max-width: 767px) {
    .login-text {
        font-size: 20px !important;
    }

    section {
        padding: 40px 20px !important;
    }

    .community-scroll {
        h5 {
            font-size: 10px !important;
        }
    }

}

@media screen and (max-width: 500px) {

    .checkbox-size {
        width: 10px !important;
    }

    .outreach-name {
        font-size: 12px !important;
    }

    .text-member {
        font-size: 12px !important;
    }

    .login-text {
        font-size: 14px !important;
    }

    .bd-text {
        font-size: 18px !important;
    }

    .check-text {
        span {
            font-size: 12px !important;
        }
    }
}

@media screen and (max-width: 575px) {
    .position-abs5 {
        margin-left: -23px !important;
        width: 40%;
    }
}

@media screen and (max-width: 390px) {
    .position-abs5 {
        margin-left: -23px !important;
        width: 60%;
    }
}